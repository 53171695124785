import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import WaitingList from '../../img/illustrations/waiting-list.svg'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section wait-listing">
          <div className="container">
            <div className="columns is-centered is-vcentered">
              <div className="column is-6">
                <img src={WaitingList} className="image" />
              </div>
              <div className="column is-6">
                <div className="content">
                  <h1>Uy.. Willing to wait siya.</h1>
                  <p>Take a moment to let us know your details about your business. We will do our best to on-board you very soon. Salamat!</p>
                  <form
                    name="waitlist"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="waitlist" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'name'}>
                        Your name:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'name'}
                          onChange={this.handleChange}
                          id={'name'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'businessName'}>
                        Business Name:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'businessName'}
                          onChange={this.handleChange}
                          id={'businessName'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'role'}>
                        Role:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'role'}
                          onChange={this.handleChange}
                          id={'role'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'category'}>
                        Category:
                      </label>
                      <div className="control">
                        <select 
                          className="input"
                          type={'text'}
                          name={'category'}
                          onChange={this.handleChange}
                          id={'category'}
                          required={true}
                        >
                          <option value="Apparel">Apparel</option>
                          <option value="Dinning">Dinning</option>
                          <option value="Gadget">Gadget</option>
                          <option value="Groceries">Groceries</option>
                          <option value="E-commerce">E-commerce</option>
                          <option value="Others">Others</option> 
                        </select>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'mobile'}>
                        Mobile Number:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'number'}
                          name={'mobile'}
                          onChange={this.handleChange}
                          id={'mobile'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'email'}>
                        Email:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'message'}>
                        Is there anything you want to add?
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'message'}
                          onChange={this.handleChange}
                          id={'message'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button className="button is-link is-warning is-large" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
